<template>
  <Panel :isActive="isActive">
    <PanelTitle :data="trail" :length="trailLength()" @close="cancel" />

    <PanelContent>
      <Fragment v-if="trail && !trail.editable">
        <div class="field push-top">
          <button class="button is-fullwidth is-light" @click.prevent="edit">
            <Icon name="icon-edit" />
            <span>{{ $t('general.edit') }}</span>
          </button>
        </div>
      </Fragment>

      <Fragment v-if="trail && trail.editable">
        <form class="form">
          <FormField :label="$t('general.name')">
            <TextInputField
              v-model="trail.label"
              :disabled="isLoading"
              :placeholder="$t('general.name')">
            </TextInputField>
          </FormField>

          <FormField :label="$t('general.color')">
            <ColorInputField
              v-model="color"
              :disabled="isLoading"
              :placeholder="$t('general.color')">
            </ColorInputField>
          </FormField>

          <div class="buttons">
            <button
              :class="{ 'is-loading': isLoading }"
              class="button is-primary"
              @click.prevent="update">
              {{ $t('general.save') }}
            </button>

            <button
              :disabled="isLoading"
              class="button"
              @click.prevent="cancel">
              {{ $t('general.cancel') }}
            </button>

            <button
              v-show="trail.id"
              :class="{ 'is-loading': isRemoving }"
              :disabled="isLoading"
              class="button is-danger"
              @click.prevent="beforeRemove">
              <Icon name="icon-trash" />
            </button>
          </div>
        </form>
      </Fragment>
    </PanelContent>
  </Panel>
</template>

<script>
import { eventManager } from '@/main'

export default {
  data () {
    return {
      huntAreaId: this.$route.params.huntAreaId,
      trailId: this.$route.params.trailId,
      state: '',
      original: {
        label: '',
        color: '#000000'
      }
    }
  },

  computed: {
    trail: {
      get () { return this.$store.getters['map/trails/selected'] },
      set (trail) { return this.$store.dispatch('map/trails/select', trail) }
    },

    isActive () {
      return this.trail !== undefined
    },

    editable: {
      get () { return this.trail ? this.trail.editable : false },
      set (state) { this.trail.editable = state }
    },

    color: {
      get () { return this.editable ? this.trail.color : '#000000' },
      set (color) { this.trail.color = color }
    },

    isLoading () { return this.state === 'loading' },
    isRemoving () { return this.state === 'removing' },

    createdAt () { return this.trail && this.$dayjs(this.trail.createdAt).format('D MMMM, HH:mm') }
  },

  methods: {
    close () {
      this.$store.dispatch('map/trails/select', null)
      this.$router.push(`/area/${this.huntAreaId}/map`)
    },

    edit () {
      this.original.label = this.trail.label
      this.original.color = this.trail.color
      this.trail.editable = true
    },

    cancel () {
      if (!this.trail) return

      if (this.editable) {
        this.trail.label = this.original.label
        this.trail.color = this.original.color
        this.trail.editable = false
        this.state = ''
      }

      this.close()
    },

    async update () {
      this.state = 'loading'

      try {
        await this.$store.dispatch('map/trails/update', {
          huntAreaId: this.huntAreaId,
          trail: this.trail
        })

        this.trail.editable = false
      } catch (error) {
        this.$notification.danger(this.$t('general.saveFailedErrorMessage'))
      }

      this.state = ''
      this.close()
    },

    async beforeRemove () {
      try {
        const response = await this.$dialog.confirm({
          title: this.$t('general.remove'),
          message: this.$t('general.reallyRemove', { name: this.trail.label }),
          cancel: this.$t('general.cancel'),
          ok: this.$t('general.remove')
        })

        if (response.ok) {
          this.remove()
        }
      } catch (error) {
        this.$notification.danger(this.$t('general.crsaveFailedErrorMessage'))
      }
    },

    async remove () {
      this.state = 'removing'

      try {
        await this.$store.dispatch('map/trails/delete', {
          huntAreaId: this.huntAreaId,
          trail: this.trail
        })

        if (this.trail) {
          this.trail.visible = false
        }

        eventManager.$emit('boundary:fitBounds')
      } catch (error) {
        this.$notification.danger(this.$t('general.saveFailedErrorMessage'))
      }

      this.cancel()
    },

    trailLength () {
      const polygon = new window.google.maps.Polyline({ path: this.trail.path })
      return Math.floor(window.google.maps.geometry.spherical.computeLength(polygon.getPath()))
    }
  }
}
</script>
